import React, { useState, useEffect } from "react";
import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { ButtonGray, ButtonGreen, ButtonLimeGreen, InputLabel, SubText1, TextHead1, TextHead4, FlexBetween, Flex, InitialDiv, Block, ButtonInitial, TextHead3, TextArea1 } from "../../components/styled/styled";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router";
import { switchSentenceCase, slotDateTimeFormatter, mergeSlotDateTime, trackPixelEvent, tokenLogin, maxCharacterLimit } from "../../utils/utilities";
import { faAngleLeft, faHatChef, faPanFrying, faPotFood } from "@fortawesome/pro-solid-svg-icons";
import ModalAutofillConfirm from "../../components/widgets/modals/ModalAutofillConfirm";
import { useDispatch, useSelector } from "react-redux";
import { getAllScheduleSlots, getSelectedSlots, readNotification, getInvitationScheduleData, getInvitationData, declineScheduleInvitation, setUserFavMeals, setOrderRedirectUrl, selfCookedSlots, } from "../schedules/redux/createScheduleReduxActions";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import { getUserInfo } from "../../services/user";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import ModalSponsorMeal from "../../components/widgets/modals/ModalSponsorMeal";
import { IonAlert, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { getUserFavoriteMeal } from "../../services/favourite";
import { checkCalendarAuthentication, getScheduleBySlug } from "../../services/schedule";
import Spinner from "../../components/widgets/spinner/Spinner";
import ModalChooseOption from "../../components/widgets/modals/ModalChooseOption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";


const WrapChoice = styled.div`
  padding: 5px;
`;

const ChoiceHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Trans = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
`;


export default function ScheduleDetailsParticipants(data: any) {
  const history = useHistory();
  const location = useLocation();
  const { schedule, invite, user } = useParams<any>();
  const dispatch = useDispatch();
  const currentDateTime = new Date();
  const userData = getUserInfo();
  const [presentAlert] = useIonAlert();
  const { notificationDetails, scheduleInvitationDetail, scheduleDetails, scheduleSlots, totalSlots } = useSelector((state: any) => state?.createScheduleForMe);
  const [showModalFill, setShowModalFill] = useState(false);
  const [showModalSponsorMeal, setShowModalSponsorMeal] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [showModalRemove, setShowModalRemove] = useState(false);
  const limit = 10;
  const [skip, setSkip] = useState(0);
  const [selectEmptySlots, setSelectEmptySlots] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [autoFillLoader, setAutoFillLoader] = useState(false);
  const isLocalStorageDataAvailable = userData?.id ? true : false;
  const [unAuthorize, setUnAuthorize] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showHomeCookedMeal, setShowHomeCookedMeal] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selfCookedDescription, setSelfCookedDescription] = useState("");

  useEffect(() => {
    //***************** CHECK USER SESSION  *****************//
    if (isLocalStorageDataAvailable) {
      (async () => {
        try {
          const checkAuthorization = await checkCalendarAuthentication(userData?.id, schedule);
          if (checkAuthorization?.data?.authorize && (userData?.slug === user?.slice(0, -1) || String(userData?.id) === user?.slice(0, -1))) {
            trackPixelEvent('Check calendar auth');
            setUnAuthorize(false);
          }
          else {
            setUnAuthorize(true);
          }
        }
        catch (e) {
          console.log(e);
          // setUnAuthorize(true);
          setLoading(false);
        }
        //if (user?.slice(0, -1) != userData?.slug && user?.slice(0, -1) != userData?.id)
      })();
    }
    else {
      (async () => {
        await tokenLogin(user, true);
      })();
    }
  }, []);

  useIonViewDidEnter(() => {
    trackPixelEvent('Calendar Invitation');
  }, []);

  useEffect(() => {
    setLoadingSlots(true);
    callData();
  }, [selectEmptySlots]);

  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  const callData = async (skippingValue = 0, showEmpty = selectEmptySlots) => {
    try {
      if (!schedule || !isLocalStorageDataAvailable || (userData?.slug !== user?.slice(0, -1) && String(userData?.id) !== user?.slice(0, -1))) {
        setLoading(false);
        return false;
      }
      const res = await getScheduleBySlug(schedule);
      if (res) {
        trackPixelEvent('Get schedule by slug');
        await dispatch(getInvitationScheduleData(res, skippingValue, limit, showEmpty) as any);
        await dispatch(getInvitationData(invite) as any);
        if (notificationDetails?.id) {
          if (notificationDetails?.attributes?.viewed === false) {
            const updatedData = {
              data: {
                viewed: true,
              },
            };
            dispatch(readNotification(notificationDetails?.id, updatedData) as any);
          }
        }
        setLoading(false);
        setLoadingSlots(false);
      }
      else {
        setLoading(false);
        setLoadingSlots(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      setLoadingSlots(false);
    }
  };

  const loadMoreSlots = async (skippingValue = 0, showEmpty = selectEmptySlots) => {
    try {
      dispatch(getAllScheduleSlots(scheduleDetails?.id, skippingValue, limit, showEmpty) as any);
    } catch (e) {
      console.log(e);
    }
  };

  const showMore = () => {
    //setLoading(true);
    const skippingValue = skip + limit;
    setSkip(skippingValue);
    loadMoreSlots(skippingValue);
  };

  const GotoHandle = () => {
    // if (unAuthorize) {
    //   setUnAuthorize(false);
    // }
    history.push({
      pathname: "/calendar-home",
    });
  };

  const openModalSponsorMeal = () => {
    if (selectedSlots?.length === 0) {
      return showAlert("", "Please select the schedule slots you want to edit.", "OK");
    } else {
      dispatch(getSelectedSlots(selectedSlots) as any);
      setShowModalSponsorMeal((prev) => !prev);
    }
  };

  const handleClickOnOrder = (type: string) => {
    if (selectedSlots?.length === 0) {
      return showAlert('', 'Please select the schedule slots you want to edit.', 'OK');
    }
    if (type === "autofill") {
      setAutoFillLoader(true);
    }
    setShowOptionModal(true);
  };

  const handleFillSlots = async (value: string) => {
    if (value === "") {
      setAutoFillLoader(false);
      return showAlert('', 'Please select any one option.', 'OK');
    }
    setShowOptionModal(false);
    dispatch(getSelectedSlots(selectedSlots) as any);
    let mealCategoriesId = [];
    scheduleDetails?.attributes?.meal_categories?.data?.forEach((currentObj: any) => {
      mealCategoriesId.push(currentObj?.id);
    });

    if (selectedSlots?.length === 0) {
      return showAlert('', 'Please select the schedule slots you want to edit.', 'OK');
    }
    else {
      if (autoFillLoader) {
        trackPixelEvent('autofill calendar');
        try {
          const query = {
            id: scheduleDetails?.attributes?.user?.data?.id,
            type: "Fav",
            skip: 0,
            limit: 100,
            lat: scheduleDetails?.attributes?.delivery_location?.data?.attributes?.latitude,
            lang: scheduleDetails?.attributes?.delivery_location?.data?.attributes?.longitude,
            category: mealCategoriesId,
            public_meal: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
            suggested: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
            limit_per_meal: Number(scheduleDetails?.attributes?.limit_per_meal),
            status: "ACTIVE",
            order_type: value
          }
          const response = await getUserFavoriteMeal(query);
          trackPixelEvent('Get user fave meal');
          setUserFavMeals(response?.data);
          setShowModalFill(true);
          setAutoFillLoader(false);
        }
        catch (error) {
          setAutoFillLoader(false);
        }
      }
      else {
        dispatch(getSelectedSlots(selectedSlots) as any);
        setOrderRedirectUrl(location.pathname)
        history.push(`/scheduleslotfill?type=${value}`);
      }
    }
  };

  const handleCheckBox = (id: any) => {
    if (selectedSlots.indexOf(id) === -1) {
      const list = [...selectedSlots];
      const newData = [id, ...list];
      setSelectedSlots(newData);
    } else {
      const i = selectedSlots.indexOf(id);
      const list = [...selectedSlots];
      list.splice(i, 1);
      setSelectedSlots(list);
    }
  };

  function handleToggleSelectAll() {
    setSelectEmptySlots(!selectEmptySlots);
    setSkip(0);
    setSelectedSlots([]);
  }

  const openModalRemove = () => {
    setShowModalRemove((prev) => !prev);
  };

  const declineMealSchedule = async () => {
    try {
      const payloadData = {
        data: {
          from: scheduleInvitationDetail?.attributes?.user?.data?.id,
          to: scheduleDetails?.attributes?.user?.data?.id,
          schedule: scheduleDetails?.id,
          invite: invite,
        },
      };
      dispatch(declineScheduleInvitation(payloadData) as any)
        .then(() => {
          history.push({ pathname: "/calendar-home" });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCookMyself = async () => {
    if (selectedSlots?.length === 0) {
      return showAlert('', 'Please select the schedule slots you want to edit.', 'OK');
    }
    else {
      setShowHomeCookedMeal(prev => !prev);
    }
  };

  const handleSubmitSelfCookedMeal = async () => {
    if (selfCookedDescription === "") {
      setDescriptionError(true);
      return false;
    }
    else {
      setDescriptionError(false);
      const payloadData = {
        data: {
          slots: selectedSlots,
          description: selfCookedDescription
        }
      }
      dispatch(selfCookedSlots(payloadData) as any)
        .then(() => {
          setShowHomeCookedMeal((prev: any) => !prev);
          setSelectedSlots([]);
        })
    }
  };


  return (
    <>
      {(loading || !isLocalStorageDataAvailable) ? (
        <div className="text-center">
          <ModalSpinner />
        </div>
      ) : (
        <div className="screen">
          <TopHead
            title="New Meal Calendar"
            leftTextIcon={faAngleLeft}
            leftIconStyle={{ fontSize: "24px" }}
            goToHandle={GotoHandle}
          />
          <Divider />

          <div className="grow overflow-auto">
            {(notificationDetails?.attributes?.schedule?.data === null) ?
              <Section>
                <div className="w-full text-center flex flex-row justify-center h-full mt-[50px]">
                  <InitialDiv>
                    <TextHead4 className="!text-[#a30000]">This meal calendar has been canceled and all slots without confirmed orders have been removed.
                    </TextHead4>
                  </InitialDiv>
                </div>
              </Section>
              :
              <>
                <Section>
                  <TextHead4>
                    You’re invited to treat{" "}
                    <span className="text-green-600">
                      {scheduleDetails?.attributes?.user?.data?.attributes?.name}
                    </span>{" "}
                    to a meal on a calendar called:
                  </TextHead4>
                  <TextHead1 style={{ fontWeight: "700" }}>
                    {switchSentenceCase("head text", scheduleDetails?.attributes?.name)}
                  </TextHead1>
                  <InputLabel style={{ fontWeight: "500" }}>
                    {scheduleDetails?.attributes?.description === "" ||
                      scheduleDetails?.attributes?.description === null ? null : (
                      <SubText1>“{scheduleDetails?.attributes?.description}!”</SubText1>
                    )}
                  </InputLabel>
                  {(loadingSlots || loading) ?
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                    :
                    <InitialDiv className="my-4">
                      <TextHead4>Pick open slots on the schedule and send meals.</TextHead4>
                      <ChoiceHead>
                        <Trans>Upcoming Time Slots</Trans>
                        <OneSideTextToggle
                          className="!font-semibold !mt-5"
                          leftText="SHOW EMPTY SLOTS"
                          style={{
                            color: "#22a45d",
                            fontSize: "15px",
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                          switchState={selectEmptySlots}
                          handleOnChangeToggle={handleToggleSelectAll}
                        />
                        {/* <OneSideTextToggle className="!font-semibold" leftText="SHOW EMPTY SLOTS" style={{ color: "#22a45d", fontSize: "13px", fontWeight: "600" }} switchState={selectEmptySlots} handleOnChangeToggle={handleToggleSelectAll} /> */}
                        {/* <Required>REQUIRED</Required> */}
                      </ChoiceHead>
                      <WrapChoice>
                        {scheduleSlots?.length > 0 ? (
                          scheduleSlots
                            .filter(
                              (items: any) =>
                                new Date(
                                  mergeSlotDateTime(items?.attributes?.date, items?.attributes?.time)
                                ) > currentDateTime
                            )
                            .map((item: any, index: any) => (
                              <React.Fragment key={index}>
                                <FlexBetween className="items-center mb-[10px] min-h-[103px]">
                                  <Flex className="items-baseline">
                                    <input
                                      type="checkbox"
                                      className="w-8 accent-green-600 cursor-pointer h-[14px] mt-[8px]"
                                      id={item?.id}
                                      checked={selectedSlots.includes(item?.id)}
                                      disabled={item?.attributes?.sponsoredBy?.data !== null}
                                      name={item?.id}
                                      onChange={(e) => handleCheckBox(item?.id)}
                                    />
                                    <Block>
                                      <InitialDiv className="flex text-[17px] font-medium my-[5px] items-baseline">
                                        <TextHead4 className="">
                                          {slotDateTimeFormatter(item?.attributes?.date, item?.attributes?.time)} {" "}
                                        </TextHead4>
                                        {item?.attributes?.order_type === "pickup" &&
                                          <TextHead4 className="ml-1">(Pickup)</TextHead4>
                                        }
                                      </InitialDiv>
                                      <span className={`${(item?.attributes?.favMeal?.data !== null || item?.attributes?.self_cooked) ? "text-[16px] text-[#8b8b8b]" : "text-[16px] text-[#c22727]"} `} >
                                        {
                                          (item?.attributes?.self_cooked)
                                            ?
                                            <div className="flex items-baseline"><FontAwesomeIcon icon={faPanFrying} className="mr-2 text-[#bbd97c]" /><div>(Home-Cooked) <br /> {item?.attributes?.self_cooked_description}</div></div>
                                            :
                                            (
                                              (item?.attributes?.favMeal?.data !== null)
                                                ?
                                                <div className="flex items-baseline"><FontAwesomeIcon icon={faPotFood} className="mr-1 text-[#bbd97c]" /><div>({switchSentenceCase("head text", item?.attributes?.favMeal?.data?.attributes?.name)})</div></div>
                                                :
                                                <div className="flex items-baseline"><FontAwesomeIcon icon={faHatChef} className="mr-2 text-[#b3b3b3] text-[14px]" /><div>({switchSentenceCase("head text", "Empty Slot")})</div></div>
                                            )
                                        }
                                      </span>
                                      {(item?.attributes?.favMeal?.data !== null && item?.attributes?.sponsoredBy?.data === null)
                                        ?
                                        <SubText1 className="mt-[2px] ml-[23px] !font-light !text-[#c22727]">
                                          {switchSentenceCase("head text", `Payment authorization required`)}
                                        </SubText1>
                                        :
                                        null}
                                      {item?.attributes?.sponsoredBy?.data !== null && (
                                        <SubText1>
                                          {item?.attributes?.sponsoredBy?.data?.id !== userData?.id
                                            ? switchSentenceCase(
                                              "head text",
                                              `Sponsored By ${item?.attributes?.sponsoredBy?.data?.attributes?.name !== null ? item?.attributes?.sponsoredBy?.data?.attributes?.name : item?.attributes?.sponsoredBy?.data?.attributes?.email !== null ? item?.attributes?.sponsoredBy?.data?.attributes?.email : item?.attributes?.sponsoredBy?.data?.attributes?.phone_no}`
                                            )
                                            : "Sponsored by You"}
                                        </SubText1>
                                      )}
                                    </Block>
                                  </Flex>
                                </FlexBetween>
                                <Divider />
                              </React.Fragment>
                            ))
                        ) : (
                          <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                            No upcoming slots.
                          </SubText1>
                        )}
                      </WrapChoice>
                      {totalSlots > limit &&
                        (totalSlots !== scheduleSlots?.length ? (
                          <div
                            className="text-green-600 text-center cursor-pointer"
                            onClick={() => {
                              showMore();
                            }}
                          >
                            See More
                            {/* <Divider /> */}
                          </div>
                        ) : (
                          <div className="text-green-600 text-center">
                            No More Slots
                            {/* <Divider /> */}
                          </div>
                        ))}
                    </InitialDiv>}
                </Section>
                {scheduleInvitationDetail?.attributes?.status === "rejected" ? (
                  <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                    You have declined this Meal Calendar!
                  </SubText1>
                ) : (
                  <Section>
                    {selectEmptySlots ? (
                      <>
                        <ButtonGreen
                          style={{ margin: "15px 0px" }}
                          onClick={() => handleClickOnOrder("order")}
                          className={`${(autoFillLoader) && "cursor-not-allowed opacity-30"}`}
                          disabled={autoFillLoader}
                        >
                          {switchSentenceCase("primary button", "ORDER FOOD FOR SELECTED SLOTS")}
                        </ButtonGreen>

                        {autoFillLoader ?
                          <div className="text-center">
                            <Spinner />
                          </div>
                          :
                          <ButtonLimeGreen
                            style={{ marginBottom: "15px" }}
                            onClick={() => handleClickOnOrder("autofill")}
                          >
                            {switchSentenceCase(
                              "primary button",
                              "AUTOFILL SELECTED WITH FAVES"
                            )}
                          </ButtonLimeGreen>
                        }

                        <ButtonInitial
                          className={autoFillLoader ? "!cursor-not-allowed text-green-600 !border !border-solid !border-green-600 opacity-30" : "text-green-600 !border !border-solid !border-green-600"}
                          style={{ marginBottom: "15px" }}
                          onClick={handleCookMyself}
                          disabled={autoFillLoader}
                        >
                          {switchSentenceCase("primary button", "I’ll Cook for Selected Slots Myself.")}
                        </ButtonInitial>

                      </>
                    ) : (
                      <ButtonGreen
                        className={`${(autoFillLoader) && "cursor-not-allowed opacity-30"}`}
                        disabled={autoFillLoader}
                        onClick={() => openModalSponsorMeal()}
                        style={{ marginBottom: "15px" }}
                      >
                        {switchSentenceCase("primary button", "SPONSOR MEAL")}
                      </ButtonGreen>
                    )}

                    {scheduleInvitationDetail?.attributes?.status !== "accepted" && (
                      <ButtonGray
                        onClick={() => {
                          openModalRemove();
                        }}
                      >
                        {switchSentenceCase("primary button", "DECLINE MEAL CALENDAR")}
                      </ButtonGray>
                    )}
                  </Section>
                )}

                {/*---------- Modal AutoFill----------- */}
                {showModalFill && (
                  <ModalAutofillConfirm
                    showModal={showModalFill}
                    setShowModal={setShowModalFill}
                    setSelectedSlots={setSelectedSlots}
                  />
                )}

                {showModalSponsorMeal && (
                  <ModalSponsorMeal
                    showModal={showModalSponsorMeal}
                    setShowModal={setShowModalSponsorMeal}
                    setSelectedSlots={setSelectedSlots}
                  />
                )}

                {/* ---------------------------------Modal Confirm Delete Popup------------------- */}
                <ModalRemoved
                  firstText="Are you sure you want to"
                  secondText="decline this Meal Schedule?"
                  btnOneText="OOPS!  NEVER MIND..."
                  btnTwoText="YES, REMOVE IT"
                  showModal={showModalRemove}
                  setShowModal={setShowModalRemove}
                  deleteItem={declineMealSchedule}
                  id={selectedSlots}
                />

                <IonAlert
                  isOpen={unAuthorize}
                  backdropDismiss={false}
                  keyboardClose={false}
                  header="Unauthorized"
                  // subHeader="Important message"
                  message="You are unauthorized to view this invitation."
                  buttons={['OK']}
                  onDidDismiss={() => history.push("/calendar-home")}
                />

                {setShowOptionModal &&
                  <ModalChooseOption
                    showModal={showOptionModal}
                    setShowModal={setShowOptionModal}
                    selectedValue={handleFillSlots}
                  />
                }

                {/* **********************************Add Slots for Home-Cooked********************************************** */}
                {
                  showHomeCookedMeal
                  &&
                  <InitialModalCenter
                    showModal={showHomeCookedMeal}
                    setShowModal={setShowHomeCookedMeal}
                  >
                    <InitialDiv className="m-4">
                      <Flex className="items-center">
                        <FontAwesomeIcon
                          className="cursor-pointer"
                          icon={faChevronLeft}
                          onClick={() => { setShowHomeCookedMeal((prev: any) => !prev); setDescriptionError(false); }}
                        />
                        <InitialDiv className="w-full text-center">
                          <TextHead3 className="">Add Home-Cooked Meal</TextHead3>
                        </InitialDiv>
                      </Flex>
                    </InitialDiv>
                    <Divider />
                    <InitialDiv className="m-4">

                      <InitialDiv className="text-left">
                        <TextHead3 id="shortNotes" className="mb-[-15px] mt-4">Enter a description of what you’ll prepare.</TextHead3>
                        <SubText1 className="text-right mt-[20px]">{selfCookedDescription?.length}/{maxCharacterLimit}</SubText1>
                        <TextArea1
                          className="!mt-[0px]"
                          name="shortNotes"
                          value={selfCookedDescription}
                          onChange={(e) => setSelfCookedDescription(e.target.value)}
                          style={{
                            border: (descriptionError) ? "1px solid red" : "",
                          }}
                          maxLength={maxCharacterLimit}
                        />
                      </InitialDiv>

                      <InitialDiv>
                        <ButtonGreen onClick={handleSubmitSelfCookedMeal}>{switchSentenceCase("head text", "SUBMIT")}</ButtonGreen>
                      </InitialDiv>

                    </InitialDiv>
                  </InitialModalCenter>
                }

              </>
            }
          </div>
        </div>
      )}
    </>
  );
}
