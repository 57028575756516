
import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TextHead3, ButtonLimeGreen, ButtonLiteGreen, ButtonGray, TextHead4, TextHead5, SubText2, Flex, FlexBetween, ItemCount, SubText1, InputText, InitialDiv, moveTopBottom } from "../../styled/styled";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faClockThree, faUtensils, faDash, faBallot, faPen, faCheck, faHouseUser, faCircleInfo, faStore } from "@fortawesome/pro-solid-svg-icons";
import Divider from "../divider/Divider";
import { slotDateTimeFormatter, slotDateFormatter, slotTimeFormatter, sumOfMealInCart, generateValidTimeOptions, existingOrderItemsFormatter, groupRestaurantSchedule, mergeSlotDateTime, filterRestaurantSchedulesByDateTime, trackPixelEvent, maskCurrencyInput, finalizeItemPriceCalculate } from "../../../utils/utilities";
import { deleteSingleSlot, getSelectedSlots, setUserFavMeals, updateSlotData } from "../../../pages/schedules/redux/createScheduleReduxActions";
import ModalRemoved from "./ModalRemoved";
import { switchSentenceCase } from "../../../utils/utilities";
import moment from "moment";
import { textFormatter } from "../../../utils/utilities";
import { priceFormatterUsd } from "../../../utils/utilities";
import { priceCalculator } from "../../../utils/utilities";
import { increasePriceByQuantity } from "../../../utils/utilities";
import AddressesCart from "../address/AdressesCart";
import { getUserInfo } from "../../../services/user";
import { Select } from "antd";
import PaymentCart from "../paymentscomponent/PaymentCart";
import Accordion from "../accordian/AccordionStyled";
import { previewOrder } from "../../../services/orderFood";
import ModalFinalizeOrder from "./ModalFinalizeOrder";
import IonModalAlert from "../alerts/IonModalAlert";
import Spinner from "../spinner/Spinner";
import { updateAddress } from "../../../services/location";
import TabsForTipPreference from "../toggleable-tabs/TabsForTipPreference";
import momentTimezone from 'moment-timezone';
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { getUserFavoriteMeal } from "../../../services/favourite";
import ModalChooseOption from "./ModalChooseOption";

const ModalDiv = styled.div`
position: fixed;
display: flex;
justify-content: center;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
z-index: 1000;
overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const BackModalBtn = styled.div`
position: relative;
    top: -20px;
    right: 52%;
    font-size: 17px;
    cursor: pointer;
`;

const DashModalIcon = styled.div`
  margin-top: -15px;
`;

const BlockContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  text-align: left;
`;

const EmptySlottext = styled.button`
  color: #22a45d;
  font-size: 13px;
  text-decoration: underline;
  margin-left: 3px;
`;

export default function ModalEditSlots(props: any) {
    const history = useHistory();
    const modalRef = useRef();
    const dispatch = useDispatch();
    const user = getUserInfo();
    const isDarkMode = useDarkMode();
    const { slotDetails, scheduleDetails } = useSelector((state: any) => state?.createScheduleForMe);
    const scheduleData = slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.time_estimate?.scheduled;
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [showModalClearSlot, setShowModalClearSlot] = useState(false);
    const [showTimeInput, setShowTimeInput] = useState(false);
    const [showInstructionInput, setShowInstructionInput] = useState(false);
    const [instructionError, setInstructionError] = useState(false);
    const [orderProcess, setOrderProcess] = useState(false);
    const [responseError, setResponseError] = useState("");
    const [showIonAlertModal, setShowIonAlertModal] = useState(false);
    const [ionAlertHeader, setIonAlertHeader] = useState("");
    const [ionAlertMessage, setIonAlertMessage] = useState("");
    const [paymentCardId, setPaymentCardId] = useState("");
    const [showFinalizeModal, setShowFinalizeModal] = useState(false);
    const [scheduleOrderedData, setScheduleOrderedData] = useState<any>([]);
    const [hideBackModal, setHideBackModal] = useState(false);
    const [restaurantSchedules, setRestaurantSchedules] = useState([]);
    const [filteredScheduleData, setFilteredScheduleData] = useState([]);
    const [showOptionModal, setShowOptionModal] = useState(false);
    const [autoFillLoader, setAutoFillLoader] = useState(false);

    const Tips = [
        {
            name: "None",
            value: "0"
        },
        {
            name: "$5",
            value: "5"
        },
        {
            name: "$10",
            value: "10"
        },
        {
            name: "$15",
            value: "15"
        },
        {
            name: "Other",
            value: "Other"
        }
    ]

    const [driverTipActive, setDriverTipActive] = useState("");
    const [driverTipValue, setDriverTipValue] = useState("");
    const [otherDriverTipValue, setOtherDriverTipValue] = useState("");
    const [otherDriverTipError, setOtherDriverTipError] = useState(false);

    const [storeTipActive, setStoreTipActive] = useState("");
    const [storeTipValue, setStoreTipValue] = useState("");
    const [otherStoreTipValue, setOtherStoreTipValue] = useState("");
    const [otherStoreTipError, setOtherStoreTipError] = useState(false);

    const handleOtherStoreTip = (val: any) => {
        let maskedValue = maskCurrencyInput(val);
        setOtherStoreTipError(false);
        setOtherStoreTipValue(maskedValue);
    }

    const handleOtherDriverTip = (val: any) => {
        let maskedValue = maskCurrencyInput(val);
        setOtherDriverTipError(false);
        setOtherDriverTipValue(maskedValue);
    };

    useEffect(() => {

        if (slotDetails?.attributes?.favMeal?.data !== null) {
            const schedules = groupRestaurantSchedule(scheduleData, slotDetails?.attributes?.delivery_location?.data?.attributes?.timezone);
            setRestaurantSchedules(schedules);

            const providedDateTime = mergeSlotDateTime(slotDetails?.attributes?.date, slotDetails?.attributes?.time) // Replace with your provided date and time
            const filteredSchedule = filterRestaurantSchedulesByDateTime(schedules, providedDateTime);
            setFilteredScheduleData(filteredSchedule)
        }

        if (slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip) {
            setDriverTipValue(Tips[0]?.value);
            setDriverTipActive(Tips[0]?.value);
        }
        else {
            const filterDriverTip = (Tips).filter((data) => data?.value === String(slotDetails?.attributes?.driver_tip));

            if (filterDriverTip?.length > 0) {
                setDriverTipValue(filterDriverTip[0]?.value);
                setDriverTipActive(filterDriverTip[0]?.value);
            } else if (slotDetails?.attributes?.driver_tip === null) {
                setDriverTipValue(Tips[0]?.value);
                setDriverTipActive(Tips[0]?.value);
            } else {
                setDriverTipValue("Other");
                setDriverTipActive(Tips[4]?.value);
                setOtherDriverTipValue("$" + slotDetails?.attributes?.driver_tip);
            }
        }

        if (slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip) {
            setStoreTipValue(Tips[0]?.value);
            setStoreTipValue(Tips[0]?.value);
        }
        else {
            const filterStoreTip = (Tips).filter((data) => data?.value === String(slotDetails?.attributes?.tip));

            if (filterStoreTip?.length > 0) {
                setStoreTipValue(filterStoreTip[0]?.value);
                setStoreTipActive(filterStoreTip[0]?.value);
            }
            else if (slotDetails?.attributes?.tip === null) {
                setStoreTipValue(Tips[0]?.value);
                setStoreTipActive(Tips[0]?.value);
            }
            else {
                setStoreTipValue("Other");
                setStoreTipActive(Tips[4]?.value);
                setOtherStoreTipValue("$" + slotDetails?.attributes?.tip);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleData]);

    const [values, setValues] = useState({
        time: slotDetails?.attributes?.time,
        instruction: slotDetails?.attributes?.instructions
    });

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false);
        }
    };

    const openModalRemove = (id: any) => {
        setShowModalRemove((prev) => !prev);
    };

    const openModalClearSlot = (id: any) => {
        setShowModalClearSlot((prev) => !prev);
    };

    const removeSlot = () => {
        try {
            dispatch(deleteSingleSlot(slotDetails?.id) as any)
                .then(() => {
                    props?.setShowModal((prev: any) => !prev);
                })
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleOnTimeChange = (value: any) => {
        setInstructionError(false);
        setValues((prevValues) => {
            return {
                ...prevValues,
                time: value,
            };
        });
    };

    const handleOnInstructionChange = (e: any) => {
        setInstructionError(false);
        const { name, value } = e.target;
        setValues((prevValues) => {
            return {
                ...prevValues,
                [name]: value,
            };
        });
    };

    const handleUpdate = async (name: any) => {
        const { time, instruction } = values;
        try {
            if (name === "time") {
                const updatedData = {
                    data: {
                        time: moment(time, 'HH:mm').format('HH:mm:ss')
                    }
                }
                if (slotDetails?.attributes?.favMeal?.data !== null) {
                    const dateTime = mergeSlotDateTime(slotDetails?.attributes?.date, slotDetails?.attributes?.time)
                    const slotTimeZone = slotDetails?.attributes?.delivery_location?.data?.attributes?.timezone?.timeZoneId;
                    const sourceDatetime = moment(new Date(dateTime)).format('YYYY-MM-DD HH:mm'); // Assuming the input datetime is in the format 'YYYY-MM-DD HH:mm'
                    const sourceTimezone = slotTimeZone;
                    const targetTimezone = process.env.REACT_APP_TARGET_TIMEZONE // Asia/Mumbai is same as Asia/Kolkata
                    const convertedTimestamp = momentTimezone.tz(sourceDatetime, sourceTimezone).tz(targetTimezone).unix();
                    const timestamp = convertedTimestamp.toString();
                    updatedData.data["delivery_timestamp"] = timestamp
                }
                dispatch(updateSlotData(slotDetails?.id, updatedData) as any)
                    .then(() => {
                        setShowTimeInput(false);
                        setValues({
                            ...values,
                            time: ""
                        })
                    })
            }
            else if (name === "instruction") {
                if (instruction.trim() === "") {
                    setInstructionError(true);
                    return false;
                }
                else setInstructionError(false);
                const updatedData = {
                    data: {
                        instructions: instruction
                    }
                }
                dispatch(updateSlotData(slotDetails?.id, updatedData) as any)
                    .then(async () => {
                        if (slotDetails?.attributes?.delivery_location?.data?.attributes?.drop_off_notes === null) {
                            const updatedData = {
                                data: {
                                    drop_off_notes: instruction
                                }
                            }
                            await updateAddress(slotDetails?.attributes?.delivery_location?.data?.id, updatedData);
                            trackPixelEvent('Update address');
                        }
                        setShowInstructionInput(false);
                        setValues({
                            ...values,
                            instruction: ""
                        })
                    })
            } else if (name === "clearSlot") {
                let updatedData = {}
                if (slotDetails?.attributes?.favMeal?.data !== null) {
                    updatedData = {
                        data: {
                            favMeal: null,
                            sponsoredBy: null,
                            order_status: null,
                            delivery_timestamp: null,
                            restaurant: null,
                            payment_card: null,
                            mealMeOrderObject: null,
                            order_obj: null,
                        }
                    }
                } else {
                    updatedData = {
                        data: {
                            self_cooked: false,
                            sponsoredBy: null,
                            order_status: null,
                            self_cooked_description: null,
                            mealMeOrderObject: null,
                            order_obj: null,
                        }
                    }
                }
                dispatch(updateSlotData(slotDetails?.id, updatedData) as any).then(() => {
                    setShowModalClearSlot(false);
                    props?.setShowModal(false);
                })
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleClickOnOrder = (type: string) => {
        if (type === "autofill") {
            setAutoFillLoader(true);
        }
        setShowOptionModal(true);
    };

    const handleFillSlots = async (value: string) => {
        if (value === "") {
            setAutoFillLoader(false);
        }
        else {
            setShowOptionModal(false);
            let mealCategoriesId = []
            scheduleDetails?.attributes?.meal_categories?.data?.forEach((currentObj: any) => {
                mealCategoriesId.push(Number(currentObj?.id));
            })
            dispatch(getSelectedSlots([slotDetails?.id]) as any);

            if (autoFillLoader) {
                try {
                    const query = {
                        id: scheduleDetails?.attributes?.user?.data?.id,
                        type: "Fav",
                        skip: 0,
                        limit: 100,
                        lat: scheduleDetails?.attributes?.delivery_location?.data?.attributes?.latitude,
                        lang: scheduleDetails?.attributes?.delivery_location?.data?.attributes?.longitude,
                        category: mealCategoriesId,
                        limit_per_meal: Number(scheduleDetails?.attributes?.limit_per_meal),
                        status: "ACTIVE",
                        order_type: value,
                        public_meal: (scheduleDetails?.attributes?.user?.data?.id !== user?.id) ? true : "All",
                        suggested: (scheduleDetails?.attributes?.user?.data?.id !== user?.id) ? true : "All",
                    }
                    const response = await getUserFavoriteMeal(query)
                    setUserFavMeals(response?.data);
                    trackPixelEvent('Get user fave meal');
                    props?.setShowModal((prev: any) => !prev);
                    props?.setShowModalFill((prev: any) => !prev);
                    setAutoFillLoader(false);
                }
                catch (error) {
                    setAutoFillLoader(false);
                }
            }
            else {
                history.push(`/scheduleslotfill?type=${value}`);
            }
        }
    };

    const handleAddressId = (id: any) => {
        if (id === slotDetails?.attributes?.delivery_location?.data?.id) {
            return false;
        }
        const updatedData = {
            data: {
                delivery_location: id
            }
        }
        dispatch(updateSlotData(slotDetails?.id, updatedData) as any)
    }

    const placeOrder = async () => {
        try {
            // const isOpenRestaurant = isRestaurantOpen(slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery, (mergeSlotDateTime(slotDetails?.attributes?.date, slotDetails?.attributes?.time)), userDeliveryAddress?.timezone, true);
            setResponseError("");
            if (orderProcess) { return false; }
            // if (!isOpenRestaurant) {
            //     setIonAlertHeader("Sorry!");
            //     setIonAlertMessage("Restaurant will be closed at this time.");
            //     setShowIonAlertModal(true);
            //     return false;
            // }
            if (paymentCardId === "" || paymentCardId === undefined) {
                setIonAlertHeader("No Payment Method Found!");
                setIonAlertMessage("Please, Add Card.");
                setShowIonAlertModal(true);
                return false;
            }
            if (slotDetails?.attributes?.order_type === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
                setOtherStoreTipError(true);
            } else {
                setOtherStoreTipError(false);
            }

            if (driverTipValue === "Other" && otherDriverTipValue === "") { setOtherDriverTipError(true) }
            else { setOtherDriverTipError(false) }

            if (
                (paymentCardId === "" || paymentCardId === undefined) ||
                (slotDetails?.attributes?.order_type === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
                (driverTipValue === "Other" && otherDriverTipValue === "") ||
                (paymentCardId === "" || paymentCardId === undefined) ||
                otherDriverTipError
            ) {
                return false;
            }
            let scheduleTimeFirstIndexId = Object.values(restaurantSchedules);
            setOrderProcess(true);
            const payloadData = {
                data: {
                    items: existingOrderItemsFormatter(slotDetails?.attributes?.favMeal?.data?.attributes?.favItems?.data),
                    delivery_location: slotDetails?.attributes?.delivery_location?.data?.id,
                    paymentCardId: paymentCardId,
                    userId: scheduleDetails?.attributes?.user?.data?.id,
                    store_id: slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.mealme_id,
                    scheduled_id: (filteredScheduleData !== undefined && filteredScheduleData.length !== 0) ? filteredScheduleData[0]?.id : Object.keys(restaurantSchedules).length > 0 ? scheduleTimeFirstIndexId[0][0]?.id : "",
                    newMeal: false,
                    date: slotDetails?.attributes?.date,
                    time: slotDetails?.attributes?.time,
                    orderType: slotDetails?.attributes?.order_type === "pickup" ? "pickup" : "delivery",
                    accepts_pickup_tip: slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData ? slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip : false,
                    accepts_delivery_tip: slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData ? slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip : false
                }
            };
            if (slotDetails?.attributes?.order_type === "pickup") {
                payloadData.data['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
            }
            if (slotDetails?.attributes?.order_type !== "pickup") {
                payloadData.data['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
                payloadData.data['instructions'] = values?.instruction
            }
            const orderData = {
                faveMealId: slotDetails?.attributes?.favMeal?.data?.id,
                newMeal: false,
                user: scheduleDetails?.attributes?.user?.data?.id,
                sponsoredBy: user?.id,
                slots: [{ id: slotDetails?.id, date: slotDetails.attributes.date, time: slotDetails.attributes.time, deliveryAddress: slotDetails.attributes?.delivery_location?.data?.id, user_id: slotDetails.attributes?.user?.data?.id }],
                paymentCardId: paymentCardId,
                orderType: slotDetails?.attributes?.order_type === "pickup" ? "pickup" : "delivery",
                accepts_pickup_tip: slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData ? slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip : false,
                accepts_delivery_tip: slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData ? slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip : false,
                calendar_name: scheduleDetails?.attributes?.name
            };
            if (slotDetails?.attributes?.order_type === "pickup") {
                orderData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
            }
            if (slotDetails?.attributes?.order_type !== "pickup") {
                orderData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
                orderData['instructions'] = values?.instruction
            }
            await previewOrder(payloadData)
                .then((res) => {
                    trackPixelEvent('Preview order');
                    const scheduleResData = res?.data;
                    const newData = ({
                        ...scheduleResData,
                        selectedSlots: [slotDetails],
                        orderData: orderData
                    })
                    setScheduleOrderedData(newData);
                    setShowFinalizeModal(prev => !prev)
                    setHideBackModal(true);
                    setOrderProcess(false);
                })
        }
        catch (e) {
            console.log(e);
            setResponseError(e?.response?.data?.data?.error);
            setOrderProcess(false);
        }
    };

    const handlePaymentId = (paymentCardId: any) => {
        setPaymentCardId(paymentCardId);
    };

    return (
        <>
            {props?.showModal ? (
                <ModalDiv onClick={closeModal}>
                    <ContentDiv className={hideBackModal ? "invisible" : ""} isDarkMode={isDarkMode}>
                        <DashModalIcon ><FontAwesomeIcon icon={faDash} style={{ fontSize: "35px", color: "#E7E7E7" }} /></DashModalIcon>
                        <InitialDiv className="mx-4">
                            <BackModalBtn>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    onClick={() => props?.setShowModal((prev: any) => !prev)}
                                />
                            </BackModalBtn>
                            <div className="text-left">
                                <InitialDiv style={{ marginTop: "-20px", marginBottom: "15px" }}>
                                    <TextHead3>{switchSentenceCase("head text", props?.schedule?.attributes?.name)} </TextHead3>
                                    <InitialDiv className="flex">
                                        <TextHead3>
                                            {slotDateTimeFormatter(slotDetails?.attributes?.date, slotDetails?.attributes?.time)}
                                        </TextHead3>
                                        {slotDetails?.attributes?.order_type === "pickup" &&
                                            <TextHead3 className="ml-2">
                                                (Pickup)
                                            </TextHead3>
                                        }
                                    </InitialDiv>
                                </InitialDiv>
                                <TextHead3 style={{ color: "#22A45D" }}>{(props?.schedule?.attributes?.type === "for_me") ? "For Me" : "For Others"}</TextHead3>
                                <FlexBetween className="my-2">
                                    <TextHead3>{(slotDetails?.attributes?.self_cooked) ? `Slot filled with Home-Cooked` : (slotDetails?.attributes?.favMeal?.data !== null) ? (switchSentenceCase("head text", `Slot filled with ${slotDetails?.attributes?.favMeal?.data?.attributes?.name}`)) : "Slot Not Yet Filled"}</TextHead3>
                                    {/* && (addressList?.length > 0) */}
                                    {(slotDetails?.attributes?.sponsoredBy?.data !== null && slotDetails?.attributes?.sponsoredBy?.data?.id === user?.id) ?
                                        <EmptySlottext className="!font-semibold" onClick={() => handleClickOnOrder("order")}>Change Meal</EmptySlottext> :
                                        ((slotDetails?.attributes?.self_cooked || slotDetails?.attributes?.favMeal?.data !== null) && (slotDetails?.attributes?.sponsoredBy?.data === null)) ?
                                            <EmptySlottext className="!font-semibold" onClick={() => handleClickOnOrder("order")}>Change Meal</EmptySlottext> : null}
                                </FlexBetween>
                                {(slotDetails?.attributes?.sponsoredBy?.data !== null) &&
                                    <SubText1>
                                        {(slotDetails?.attributes?.sponsoredBy?.data?.id !== user?.id) ?
                                            switchSentenceCase("head text", `Sponsored By ${(slotDetails?.attributes?.sponsoredBy?.data?.attributes?.name !== null) ? (slotDetails?.attributes?.sponsoredBy?.data?.attributes?.name) : (slotDetails?.attributes?.sponsoredBy?.data?.attributes?.email !== null) ? (slotDetails?.attributes?.sponsoredBy?.data?.attributes?.email) : (slotDetails?.attributes?.sponsoredBy?.data?.attributes?.phone_no)}`)
                                            :
                                            "Sponsored by You"
                                        }
                                    </SubText1>
                                }
                                <div className="my-5">
                                    {(slotDetails?.attributes?.self_cooked) ? (
                                        <InitialDiv className="text-left text-[#7a7a7aba] flex items-baseline">
                                            <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[12px]" />
                                            <TextHead5 className="font-medium">{slotDetails?.attributes?.self_cooked_description}</TextHead5>
                                        </InitialDiv>
                                    ) : (
                                        (slotDetails?.attributes?.mealMeOrderObject !== null) ? (
                                            <>
                                                <TextHead5>Meal Items</TextHead5>
                                                {slotDetails?.attributes?.mealMeOrderObject?.final_quote?.items.map((item: any, index: any) => {
                                                    return (
                                                        <Flex key={index} className="mb-2">
                                                            <ItemCount>{index + 1}</ItemCount>
                                                            <BlockContainer>
                                                                <TextHead4>
                                                                    {switchSentenceCase("head text", textFormatter(item?.name))}
                                                                </TextHead4>

                                                                <FlexBetween className="my-2">
                                                                    <SubText1>
                                                                        {priceFormatterUsd((finalizeItemPriceCalculate(item?.customizations)) + item?.base_price)} x {item?.quantity}
                                                                    </SubText1>
                                                                    <SubText1>
                                                                        {priceFormatterUsd(increasePriceByQuantity(finalizeItemPriceCalculate(item?.customizations) + item?.base_price, item?.quantity))}
                                                                    </SubText1>
                                                                </FlexBetween>
                                                            </BlockContainer>
                                                        </Flex>
                                                    )
                                                })}
                                                <section>
                                                    <FlexBetween >
                                                        <TextHead5 style={{ fontWeight: "500" }}>Subtotal</TextHead5>
                                                        <TextHead5 style={{ fontWeight: "500" }}>
                                                            {priceFormatterUsd(slotDetails?.attributes?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0)}
                                                        </TextHead5>
                                                    </FlexBetween>
                                                </section>
                                            </>
                                        ) : (
                                            (slotDetails?.attributes?.favMeal?.data !== null) ? (
                                                <>
                                                    <TextHead5>Meal Items</TextHead5>
                                                    {slotDetails?.attributes?.favMeal?.data?.attributes?.favItems?.data?.map((items: any, index: any) => {
                                                        return (
                                                            <Flex key={index} className="mb-2">
                                                                <ItemCount>{index + 1}</ItemCount>
                                                                <BlockContainer>
                                                                    <TextHead4>
                                                                        {switchSentenceCase("head text", textFormatter(items?.attributes?.product?.data?.attributes?.name))}
                                                                    </TextHead4>

                                                                    <FlexBetween className="my-2">
                                                                        <SubText1>
                                                                            {priceFormatterUsd(priceCalculator(items?.attributes?.product?.data?.attributes?.price, items?.attributes?.customizations))} x {items?.attributes?.quantity}
                                                                        </SubText1>
                                                                        <SubText1>
                                                                            {
                                                                                priceFormatterUsd(
                                                                                    increasePriceByQuantity(
                                                                                        priceCalculator(items?.attributes?.product?.data?.attributes?.price, items?.attributes?.customizations),
                                                                                        items?.attributes?.quantity
                                                                                    ),
                                                                                )
                                                                            }
                                                                        </SubText1>
                                                                    </FlexBetween>
                                                                </BlockContainer>
                                                            </Flex>
                                                        )
                                                    })}
                                                    <section>
                                                        <FlexBetween >
                                                            <TextHead5 style={{ fontWeight: "500" }}>Subtotal</TextHead5>
                                                            <TextHead5 style={{ fontWeight: "500" }}>${sumOfMealInCart(slotDetails?.attributes?.favMeal?.data?.attributes?.favItems?.data)}</TextHead5>
                                                        </FlexBetween>
                                                    </section>
                                                </>
                                            ) : (
                                                <div className="flex">
                                                    <FontAwesomeIcon icon={faUtensils} className="mt-1" />
                                                    <div className="ml-5">
                                                        <ButtonLiteGreen
                                                            style={{ marginBottom: "10px" }}
                                                            className={autoFillLoader && "!cursor-not-allowed opacity-30"}
                                                            disabled={autoFillLoader}
                                                            onClick={() => handleClickOnOrder("order")}
                                                        >
                                                            {switchSentenceCase(
                                                                "primary button",
                                                                "ORDER FOOD FOR THIS SLOT"
                                                            )}
                                                        </ButtonLiteGreen>
                                                        {autoFillLoader ?
                                                            <div className="text-center">
                                                                <Spinner />
                                                            </div>
                                                            :
                                                            <ButtonLimeGreen
                                                                className={autoFillLoader ? `opacity-30 mt-2 !cursor-not-allowed` : ""}
                                                                disabled={autoFillLoader}
                                                                onClick={() => handleClickOnOrder("autofill")}>
                                                                {switchSentenceCase("primary button", "AUTOFILL FAVES FOR SLOT")}
                                                            </ButtonLimeGreen>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        )
                                    )}
                                </div>
                                <Divider />
                                <InitialDiv className="my-4">
                                    <div className="flex">
                                        <FontAwesomeIcon icon={faClockThree} className="mt-1" />
                                        <div className="ml-2 w-full">
                                            <TextHead4 className="flex justify-between">Time <a onClick={() => setShowTimeInput((prev) => !prev)}> {(showTimeInput) ? <FontAwesomeIcon icon={faCheck} className="text-green-600 cursor-pointer" /> : <FontAwesomeIcon icon={faPen} className="text-green-600 text-xs cursor-pointer" />}</a></TextHead4>
                                            <SubText1>Arrives at {slotTimeFormatter(slotDetails?.attributes?.time)}
                                                {(showTimeInput) &&
                                                    <FlexBetween>
                                                        {/* <input type="Time" name="time" value={values?.time} onChange={(e: any) => handleOnChange(e)}
                                                            className={`time-input-custom mr-2 h-9 cursor-pointer accent-green-600`} /> */}
                                                        <Select
                                                            className={isDarkMode ? "time-selector" : ""}
                                                            allowClear
                                                            showSearch
                                                            defaultValue="Please select time"
                                                            bordered={false}
                                                            optionFilterProp="label"
                                                            onChange={(e: any) => handleOnTimeChange(e)}
                                                            options={generateValidTimeOptions(slotDetails?.attributes?.date)}
                                                            style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                                                        />
                                                        <EmptySlottext className="!font-semibold !ml-4" onClick={() => handleUpdate("time")}>Update</EmptySlottext>
                                                    </FlexBetween>
                                                }
                                            </SubText1>
                                            <SubText1 >Date: {slotDateFormatter(slotDetails?.attributes?.date)}</SubText1>
                                        </div>
                                    </div>
                                </InitialDiv>
                                <Divider />
                                <InitialDiv className="my-4">
                                    <div className="flex">
                                        <FontAwesomeIcon icon={slotDetails?.attributes?.order_type === "pickup" ? faStore : faHouseUser} className="mt-1" />
                                        <div className="ml-2 w-full">
                                            <InitialDiv>
                                                {slotDetails?.attributes?.order_type === "pickup" ?
                                                    <TextHead4>
                                                        Store Address
                                                        <SubText1>
                                                            {(slotDetails?.attributes?.mealMeOrderObject !== null) ?
                                                                slotDetails?.attributes?.mealMeOrderObject?.final_quote?.store_address :
                                                                `${slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.street_addr || ''}, ${slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.city}, ${slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.state}, ${slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.zipcode}, ${slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.country}`
                                                            }
                                                        </SubText1>
                                                    </TextHead4>
                                                    :
                                                    <AddressesCart title={"Delivery Address"} addressId={handleAddressId} showDeleteBtn={false} showList={false} pageName="scheduleSlotsEdit" pageDataId={slotDetails?.attributes?.delivery_location?.data?.id} changeAddressTxtBtn="" userId={scheduleDetails?.attributes?.user?.data?.id} setDeliveryAddress={true} changeAddress={false} initialLength={1} />
                                                }
                                            </InitialDiv>
                                        </div>
                                    </div>
                                </InitialDiv>

                                {((slotDetails?.attributes?.favMeal?.data !== null) && (slotDetails?.attributes?.sponsoredBy?.data === null))
                                    &&
                                    <>
                                        <Divider />
                                        {/********************  Driver's Tip Section ******************************/}
                                        {slotDetails?.attributes?.order_type !== "pickup" &&
                                            <InitialDiv className="my-4">
                                                <TextHead3 className="">Driver's Tip</TextHead3>

                                                {(slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip) && (
                                                    <InitialDiv className="">
                                                        <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                                                        <span
                                                            style={{
                                                                fontWeight: "350",
                                                                color: "#dc2626",
                                                                fontSize: "17px",
                                                            }}
                                                        >
                                                            Driver tip is not available for this restaurant.
                                                        </span>
                                                    </InitialDiv>
                                                )}
                                                <TabsForTipPreference
                                                    types={Tips}
                                                    setTipValue={setDriverTipValue}
                                                    setActive={setDriverTipActive}
                                                    selected={driverTipActive}
                                                    disabled={slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip}
                                                />
                                                {(driverTipValue === "Other")
                                                    ?
                                                    <InputText
                                                        type="text"
                                                        inputMode="numeric"
                                                        style={{ border: otherDriverTipError ? "1px solid red" : "" }}
                                                        min={0}
                                                        value={otherDriverTipValue}
                                                        disabled={slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip}
                                                        placeholder="Enter Amount in Cents"
                                                        onChange={(event: any) => handleOtherDriverTip(event.target.value)}
                                                    />
                                                    :
                                                    null
                                                }
                                            </InitialDiv>}

                                        {/********************  Store Tip Section ******************************/}
                                        {slotDetails?.attributes?.order_type === "pickup" &&
                                            <InitialDiv className="my-4">
                                                <TextHead3 className="text-left">Store Tip</TextHead3>
                                                {slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip && (
                                                    <InitialDiv className="text-left">
                                                        <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                                                        <span
                                                            style={{
                                                                fontWeight: "350",
                                                                color: "#dc2626",
                                                                fontSize: "17px",
                                                            }}
                                                        >
                                                            Store tip is not available for this restaurant.
                                                        </span>
                                                    </InitialDiv>
                                                )}
                                                <TabsForTipPreference
                                                    types={Tips}
                                                    setTipValue={setStoreTipValue}
                                                    setActive={setStoreTipActive}
                                                    selected={storeTipActive}
                                                    disabled={slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip}
                                                />
                                                {(storeTipValue === "Other")
                                                    ?
                                                    <InputText
                                                        type="text"
                                                        inputMode="numeric"
                                                        style={{
                                                            border: otherStoreTipError ? "1px solid red" : "",
                                                        }}
                                                        min={0}
                                                        value={otherStoreTipValue}
                                                        disabled={slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData && !slotDetails?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip}
                                                        placeholder="Enter Amount in Cents"
                                                        onChange={(event: any) => handleOtherStoreTip(event.target.value)}
                                                    />
                                                    :
                                                    null
                                                }
                                            </InitialDiv>
                                        }
                                        <InitialDiv className="my-4">
                                            {/********************  Payment Section Section ******************************/}
                                            <Accordion title="Payment" opened={(paymentCardId === "" || paymentCardId === undefined) ? true : false}>
                                                <PaymentCart paymentId={handlePaymentId} userId={user?.id} changePayment={true} selectedCard={null} />
                                            </Accordion>
                                        </InitialDiv>
                                    </>
                                }
                                {slotDetails?.attributes?.order_type !== "pickup" &&
                                    <>
                                        <Divider />
                                        <InitialDiv className="my-4">
                                            <div className="flex">
                                                <FontAwesomeIcon icon={faBallot} className="mt-1" />
                                                <div className="ml-2 w-full">
                                                    <TextHead4>Dropoff Notes</TextHead4>
                                                    <InitialDiv>
                                                        <SubText2 className="flex justify-between mt-1">{(switchSentenceCase("head text", slotDetails?.attributes?.instructions))}
                                                            {(slotDetails?.attributes?.instructions !== null) &&
                                                                <a onClick={() => setShowInstructionInput((prev) => !prev)}> {(showInstructionInput) ? <FontAwesomeIcon icon={faCheck} className="text-green-600 cursor-pointer" /> : <FontAwesomeIcon icon={faPen} className="text-green-600 text-xs cursor-pointer" />}
                                                                </a>
                                                            }
                                                        </SubText2>
                                                        {(showInstructionInput || slotDetails?.attributes?.instructions === null) &&
                                                            <FlexBetween className="mt-1">
                                                                <InputText
                                                                    placeholder=""
                                                                    className="mr-2"
                                                                    style={{
                                                                        border: instructionError ? "1px solid red" : "",
                                                                    }}
                                                                    name="instruction"
                                                                    value={values?.instruction}
                                                                    onChange={(e: any) => handleOnInstructionChange(e)}
                                                                >
                                                                </InputText>
                                                                <EmptySlottext className="!font-semibold" onClick={() => handleUpdate("instruction")}>Update</EmptySlottext>
                                                            </FlexBetween>
                                                        }
                                                    </InitialDiv>
                                                </div>
                                            </div>
                                        </InitialDiv>
                                    </>
                                }
                                {(scheduleDetails?.attributes?.user?.data?.id === user?.id || scheduleDetails?.attributes?.from_user?.data?.id === user?.id || scheduleDetails?.attributes?.from_user?.data?.attributes?.slug === user?.slug || scheduleDetails?.attributes?.user?.data?.attributes?.slug === user?.slug)
                                    &&
                                    <ButtonGray
                                        className={`${(slotDetails?.attributes?.sponsoredBy?.data !== null) && "!cursor-not-allowed"}`}
                                        disabled={slotDetails?.attributes?.sponsoredBy?.data !== null}
                                        onClick={() => { openModalRemove(slotDetails?.id) }}
                                    >
                                        {switchSentenceCase("primary button", "Remove Slot")}
                                    </ButtonGray>
                                }
                                {(slotDetails?.attributes?.favMeal?.data !== null || slotDetails?.attributes?.self_cooked)
                                    &&
                                    <ButtonLimeGreen
                                        className={(orderProcess) ? "!cursor-not-allowed !border !border-solid !border-lime-200 !bg-lime-200" : ""}
                                        disabled={orderProcess}
                                        style={{ margin: "5px 0px" }}
                                        onClick={() => { openModalClearSlot(slotDetails?.id) }}
                                    >
                                        {switchSentenceCase("primary button", "CLEAR MEAL AND KEEP SLOT")}
                                    </ButtonLimeGreen>
                                }
                                {((slotDetails?.attributes?.favMeal?.data !== null) && (slotDetails?.attributes?.sponsoredBy?.data === null))
                                    &&
                                    <>
                                        {
                                            orderProcess
                                                ?
                                                <div className="text-center">
                                                    < Spinner />
                                                </div>
                                                :
                                                <ButtonLimeGreen
                                                    className={(orderProcess) ? "!cursor-not-allowed !text-lime-400 !border !border-solid !border-lime-200 !bg-lime-200" : ""}
                                                    disabled={orderProcess}
                                                    style={{ margin: "5px 0px" }}
                                                    onClick={() => placeOrder()}
                                                >
                                                    {switchSentenceCase("primary button", "CONTINUE TO PREVIEW ORDER")}
                                                </ButtonLimeGreen>
                                        }
                                        {responseError && <p className="text-red-600 text-center">{(responseError === "Scheduled id is not valid or not available.") ? "Something went wrong. Please retry or 'Contact Us' in your profile." : responseError}</p>}
                                    </>
                                }
                                {/* <button className="sc-ksBlkl bOSPzg" onClick={() => { openModalRemove(props?.data?.id) }}>Remove This</button> */}
                            </div>
                        </InitialDiv>
                        {/* </IntialDiv> */}
                    </ContentDiv>
                </ModalDiv >
            )
                :
                null
            }
            {/* ---------------------------------Modal Confirm Delete Popup------------------- */}
            {
                showModalRemove &&
                <ModalRemoved
                    firstText="Are you sure you want to"
                    secondText="remove this Slot?"
                    btnOneText="OOPS!  NEVER MIND..."
                    btnTwoText="YES, REMOVE IT"
                    showModal={showModalRemove}
                    setShowModal={setShowModalRemove}
                    deleteItem={removeSlot}
                    id={slotDetails?.id}
                />
            }
            {/* ---------------------------------Modal Confirm Clear Slot Data Popup------------------- */}
            {
                showModalClearSlot
                &&
                <ModalRemoved
                    firstText="Are you sure you want to"
                    secondText="clear this slot data?"
                    btnOneText="OOPS!  NEVER MIND..."
                    btnTwoText="YES, CLEAR IT"
                    showModal={showModalClearSlot}
                    setShowModal={setShowModalClearSlot}
                    deleteItem={() => handleUpdate("clearSlot")}
                    id={slotDetails?.id}
                />
            }
            {/* *********************Modal Finalize Order****************************** */}
            {
                showFinalizeModal &&
                < ModalFinalizeOrder
                    showFinalizeModal={showFinalizeModal}
                    setShowFinalizeModal={setShowFinalizeModal}
                    finalizeData={scheduleOrderedData}
                    closeBackModal={props?.setShowModal}
                    setHideBackModal={setHideBackModal}
                />
            }
            {/* ********************* Alert Modal for validations ****************************** */}
            <IonModalAlert
                isOpened={showIonAlertModal}
                setIsOpened={setShowIonAlertModal}
                header={ionAlertHeader}
                subHeader=""
                message={ionAlertMessage}
            />

            {setShowOptionModal &&
                <ModalChooseOption
                    showModal={showOptionModal}
                    setShowModal={setShowOptionModal}
                    selectedValue={handleFillSlots}
                />
            }
        </>
    );
}