import { Flex } from 'antd';
import styled from 'styled-components';
import { InitialDiv, SubText2, TextHead1 } from '../../styled/styled';
import useDarkMode from '../../hooks/darkmode/DarkMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';


const CardBackground = styled.div<{ isDarkMode: boolean }>`
  background-color: ${props => (props?.isDarkMode ? '#ffff' : '#f5f5f5')};
  width: 100%;
  border-radius: 10px;
  margin: 10px 0px;
`;

const LeftSideText = styled.div`
  color: white;
  background: linear-gradient(to bottom right, #0f994e, #25cd71, #086532);
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
`;

const Circle1 = styled.div<{ isDarkMode: boolean }>`
  background: ${props => (props?.isDarkMode ? '#000000' : '#fff')};
  min-width: 18px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  top: 10px;
  transform: translateY(-50%);
  left: -9px;
`;

const Circle2 = styled.div<{ isDarkMode: boolean }>`
  background: ${props => (props?.isDarkMode ? '#000000' : '#fff')};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  top: 50px;
  transform: translateY(-50%);
  left: 9px;
`;

export default function CardApplyCoupon(props: any) {
  const isDarkMode = useDarkMode();
  const { discountAmt, cartValue, discountType, couponCode, description, styleDiv, handleApply, handleRemove, isApplied } = props;

  // Calculate the savings
  let savings = 0;
  if (discountType === "flat") {
    savings = parseFloat(discountAmt);
  } else if (discountType === "percentage") {
    savings = (parseFloat(discountAmt) / 100) * parseFloat(cartValue);
  };


  return (
    <CardBackground isDarkMode={isDarkMode}>
      <Flex style={styleDiv}>
        <LeftSideText>
          <Circle1 isDarkMode={isDarkMode}></Circle1>
          {discountType === "flat" ? (
            <TextHead1 className='rotate-45 !text-[18px] text-center !font-bold mr-3'>
              FLAT ${discountAmt} OFF
            </TextHead1>
          ) : (
            <TextHead1 className='rotate-45 !text-[18px] text-center !font-bold mr-3'>
              {discountAmt}%OFF
            </TextHead1>
          )}
        </LeftSideText>
        <InitialDiv className='w-full m-2'>
          <InitialDiv className='text-left my-[10px]'>
            {/* <Flex className='justify-between items-baseline'>
              <TextHead1 className='text-black !text-[19px] !font-semibold'>{couponCode}</TextHead1>
              {isApplied ? (
                <button
                  className='!text-[#22A45D] !font-bold'
                  onClick={handleRemove}
                >
                  APPLIED <FontAwesomeIcon icon={faCircleXmark} className="text-[#b7b7b7]" />
                </button>
              ) : (
                <button
                  className='!text-[#22A45D] !font-bold'
                  onClick={() => handleApply(couponCode)}
                >
                  APPLY
                </button>
              )}
            </Flex> */}
            <SubText2 className='!text-[#7bb10a] !font-semibold'>
              Save ${savings.toFixed(2)} on this order!
            </SubText2>
          </InitialDiv>
          <hr className='border-2 border-dotted my-1' />
          <InitialDiv className='text-center'>
            {isApplied ? (
              <button
                className='!text-[#22A45D] !font-bold'
                onClick={handleRemove}
              >
                APPLIED <FontAwesomeIcon icon={faCircleXmark} className="text-[#b7b7b7]" />
              </button>
            ) : (
              <button
                className='!text-[#22A45D] !font-bold'
                onClick={() => handleApply(couponCode)}
              >
                APPLY
              </button>
            )}
          </InitialDiv>
          {/* <InitialDiv className='text-left'>
            <SubText2 className='text-black'>{description}</SubText2>
          </InitialDiv> */}
        </InitialDiv>
        <InitialDiv>
          <Circle2 isDarkMode={isDarkMode}></Circle2>
        </InitialDiv>
      </Flex>
    </CardBackground>
  );
}
