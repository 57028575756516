import { useEffect, useState } from 'react';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { AppleLoginButton } from 'react-social-login-buttons';
import { objectType } from '../../type';
import { setUserData, socialLogin } from '../../services/user';
import { getPlatforms, useIonAlert, useIonRouter } from '@ionic/react';
import { useHistory } from 'react-router';
import store from '../../redux/store';
import { jwtDecode } from "jwt-decode";
import Spinner from '../../components/widgets/spinner/Spinner';
import useDeviceInfo from '../../components/hooks/seDeviceInfo';


export default function SigninWithApple({ loginState }) {
    const router = useIonRouter();
    const history = useHistory();
    const platform = getPlatforms();
    const deviceInfo = useDeviceInfo();
    const [awaiting, setAwaiting] = useState(false);
    const [presentAlert] = useIonAlert();
    const options: SignInWithAppleOptions = {
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID, //com.stranerd.dev
        redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI, //https://dev.stranerd.com
        scopes: 'name email', // Scopes should be an array of strings
        state: '12345',
        nonce: generateNonce(),
    };

    const showAlert = (header: string, message: string, btnMessage: string) => {
        presentAlert({
            header: header,
            message: message,
            buttons: [btnMessage],
        });
    };

    useEffect(() => {
        const isSafari = /^((?!chrome|android|CriOS).)*safari/i.test(navigator.userAgent) && !/CriOS/i.test(navigator.userAgent);
        // console.log("isSafari", isSafari);
        if (isSafari && deviceInfo?.platform === "web" && (deviceInfo?.operatingSystem === "mac" || deviceInfo?.operatingSystem === "ios") && !/Google/i.test(deviceInfo?.manufacturer)) {
            SignInWithApple.authorize(options);
        }
    }, [deviceInfo])

    // console.log("navigator.userAgent", navigator.userAgent);

    // console.log("deviceInfo", deviceInfo, "platform", platform);

    const handleAppleSignIn = async () => {
        try {
            const result: SignInWithAppleResponse = await SignInWithApple.authorize(options);
            if (result?.response?.identityToken) {
                if (result?.response?.email) {
                    handleLogin('apple', result?.response);
                }
                else {
                    const decode_data: objectType = jwtDecode(result?.response?.identityToken);
                    // Update data object to include email from decoded token
                    const data = {
                        ...result?.response,
                        email: decode_data?.email
                    };
                    handleLogin('apple', data);
                }
            }
        } catch (error) {
            // Handle error
            setAwaiting(false);
            store.dispatch({
                type: "SET_LOGIN_STATE",
                payload: { loginState: false }
            })
            if (error?.error === "popup_closed_by_user") {
                return;
            } else if (error?.error === "popup_blocked_by_browser") {
                return showAlert('Blocked!', 'The login popup has been blocked. Please enable popups in your browser settings to proceed.', 'OK');
            }
            else {
                return showAlert('Error', 'Login failed. Please try again.', 'OK');
            }
        }
    };

    function generateNonce() {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        return array[0].toString(16).padStart(10, '0'); // Convert to hex string
    }

    const handleLogin = async (provider: string, data: objectType) => {
        try {
            if (data?.email && data?.authorizationCode) {
                const local_data = {
                    email: data?.email,
                    name: data?.name ? data?.name : "",
                    verified_email: data?.email_verified,
                    token: data?.authorizationCode
                };
                await socialLogin(JSON.stringify(local_data), provider).then(
                    async (response: any) => {
                        let data = response?.data;

                        // Google Analytics event snippet
                        if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
                            if (window.gtag && data?.user?.new_user === true) {
                                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/D9w9CMi297cZEMOLp7s9' });
                            } else if (window.gtag && data?.user?.new_user === false) {
                                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/tNh_CPmurrkZEMOLp7s9', 'value': 1.0, 'currency': 'USD' });
                            }
                        }
                        // Google Analytics event snippet end

                        await setUserData(data);
                        setAwaiting(false);
                        store.dispatch({
                            type: "SET_LOGIN_STATE",
                            payload: { loginState: false }
                        });
                        if (router.routeInfo.pathname === '/login') {
                            history.push('/onboarding');
                        }
                        else {
                            store.dispatch({
                                type: "SHOW_ALERT",
                                payload: { show: false }
                            })
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        // console.error('Error Social Login:', error);
                        setAwaiting(false);
                        store.dispatch({
                            type: "SET_LOGIN_STATE",
                            payload: { loginState: false }
                        });
                    });
            }
        }
        catch (error) {
            // console.log("Error login with apple", error);
            setAwaiting(false);
            store.dispatch({
                type: "SET_LOGIN_STATE",
                payload: { loginState: false }
            });
        }
    }

    return (
        <>
            {awaiting ?
                <div className="text-center">
                    <Spinner />
                </div>
                :
                <div>
                    <AppleLoginButton className='google-login-button apple-button' style={{ display: "flex", justifyContent: "center" }}
                        onClick={() => {
                            setAwaiting(true);
                            store.dispatch({
                                type: "SET_LOGIN_STATE",
                                payload: { loginState: true }
                            });
                            handleAppleSignIn();
                        }}
                        disabled={loginState ? true : false}
                    >
                        <span>Sign in with Apple</span>
                    </AppleLoginButton>
                </div>
            }
        </>
    );
};
